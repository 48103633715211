body { background-color: $COL2; }
body { color: $COL3; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL4;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 34px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL5;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL6;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 26px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL7;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL8;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL9;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 24px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL12;
font-family: 'Oswald';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
summary {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 2;
letter-spacing: 0;
font-size: 25px;

}
body {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: $COL10}
a:hover {color: $COL14}
/* Secondary:2 */
.MES2 {
background-color: $COL11;
color: $COL16;
 }
/* Secondary:3 */
.MES3 {
background-color: $COL11;
color: $COL16;
 }
.MES3 {
background-color: $COL11;
color: $COL16;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL16;
 }
 }
a.MEC3 { color: $COL2;
&:hover { color: $COL27; }
 }
cite.MEC3{
color: $COL16;
}
/* Primary:4 */
.MES4 {
background-color: $COL13;
color: $COL15;
 }
/* Primary:5 */
.MES5 {
background-color: $COL13;
color: $COL15;
 }
.MES5 {
background-color: $COL13;
color: $COL15;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL15;
 }
 }
cite.MEC5{
color: $COL15;
}
/* Shade 3:6 */
.MES6 {
background-color: $COL17;
color: $COL21;
 }
/* Shade 3:7 */
.MES7 {
background-color: $COL17;
color: $COL21;
 }
.MES7 {
background-color: $COL17;
color: $COL21;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL21;
 }
 }
cite.MEC7{
color: $COL21;
}
/* Alternate:8 */
.MES8 {
background-color: $COL25;
color: $COL18;
 }
/* Alternate:9 */
.MES9 {
background-color: $COL25;
color: $COL18;
 }
.MES9 {
background-color: $COL25;
color: $COL18;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL18;
 }
 }
cite.MEC9{
color: $COL18;
}
/* Light:10 */
.MES10 {
background-color: $COL26;
color: $COL19;
 }
/* Light:11 */
.MES11 {
background-color: $COL26;
color: $COL19;
 }
.MES11 {
background-color: $COL26;
color: $COL19;
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: $COL19;
 }
 }
cite.MEC11{
color: $COL19;
}
/* Dark:12 */
.MES12 {
background-color: $COL20;
color: $COL22;
 }
/* Dark:13 */
.MES13 {
background-color: $COL20;
color: $COL22;
 }
.MES13 {
background-color: $COL20;
color: $COL22;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: $COL22;
 }
 }
cite.MEC13{
color: $COL22;
}
/* Shade 1:14 */
.MES14 {
background-color: $COL27;
color: $COL23;
 }
/* Shade 1:15 */
.MES15 {
background-color: $COL27;
color: $COL23;
 }
.MES15 {
background-color: $COL27;
color: $COL23;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL23;
 }
 }
cite.MEC15{
color: $COL23;
}
/* Shade 2:16 */
.MES16 {
background-color: $COL28;
color: $COL24;
 }
/* Shade 2:17 */
.MES17 {
background-color: $COL28;
color: $COL24;
 }
.MES17 {
background-color: $COL28;
color: $COL24;
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: $COL24;
 }
 }
cite.MEC17{
color: $COL24;
}
/* Top Menu:18 */
nav.me-Menu.MES18 {
& .menu-item.MEC18, & .menu-item.MEC18 > div.MEC18{ & > a.MEC18{color: $COL13;
}
 &:hover > a.MEC18{color: $COL13;
}
 }
&.horizontal > .menu-item.MEC18 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC18 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC18 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC18 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Main Menu:19 */
nav.me-Menu.MES19 {
& .menu-item.MEC19, & .menu-item.MEC19 > div.MEC19{ & > a.MEC19{color: $COL11;
font-size: 19.2px;
@media #{$large-up} {
font-size: 22.4px;
}text-transform: capitalize;
}
 &:hover > a.MEC19{color: $COL17;
}
 }
&.horizontal > .menu-item.MEC19 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC19 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC19 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC19 .sub-menu { border:1px;
border-color: $COL11;
 }
& > .menu-item > a{padding: 0 10px;}

& .sub-menu .menu-item a{padding: 0;}

@media #{$large-up} {
& .sub-menu .menu-item a{padding: 4px;}

}


&.horizontal .menu-item.MEC19:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL31;}}
&.vertical .menu-item.MEC19:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL31;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC19{background-color: $COL31;
}
& .menu-item.MEC19, & .menu-item.MEC19 > div.MEC19{ & > a.MEC19{color: $COL17;
font-size: 16px;
}
 &:hover > a.MEC19{color: $COL11;
}
 }

}
}
 }
/* Phone number:20 */
.MES20 {
background-color: $COL11;
font-size: 22.4px;
@media #{$large-up} {
font-size: 24px;
};
 }
.MES20 {
background-color: $COL11;
font-size: 22.4px;
@media #{$large-up} {
font-size: 24px;
};
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL2;
 }
 }
a.MEC20 { color: $COL2;
&:hover { color: $COL13; }
 }
cite.MEC20{
font-size: 22.4px;
@media #{$large-up} {
font-size: 24px;
};
}
/* Background Image:21 */
.MES21 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://seagetransport.com.au/img/47/26');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
 }
.MES21 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://seagetransport.com.au/img/47/26');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 300px;};
 }
/* Header Scroll Style:22 */
.MES22 {
background-color: $COL32;
&:hover, &.hover { background-color: $COL31;}
color: $COL11;
 }
.MES22 {
background-color: $COL32;
&:hover, &.hover { background-color: $COL31;}
color: $COL11;
h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: $COL11;
 }
 }
a.MEC22 { color: $COL11;
&:hover { color: $COL11; }
 }
cite.MEC22{
color: $COL11;
}
/* Main Menu Scroll:23 */
nav.me-Menu.MES23 {
& .menu-item.MEC23, & .menu-item.MEC23 > div.MEC23{ & > a.MEC23{color: $COL2;
}
 &:hover > a.MEC23{color: $COL2;
}
 }
&.horizontal > .menu-item.MEC23 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC23 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC23 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC23 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Header Scroll:24 */
.MES24 {
background-color: $COL1;
&:hover, &.hover { background-color: $COL1;}
color: $COL11;
 }
.MES24 {
background-color: $COL1;
&:hover, &.hover { background-color: $COL1;}
color: $COL11;
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: $COL11;
 }
 }
a.MEC24 { color: $COL11;
&:hover { color: $COL11; }
 }
cite.MEC24{
color: $COL11;
}
/* Rsp Menu:25 */
nav.responsive-menu {
& .menu-item.MEC25, & .menu-item.MEC25 > div.menu-item-wrap{ & > a.MEC25, & > i{color: $COL17;
text-align: center;
}
  }
& .menu-item.MEC25 { border:0;

 border-bottom-width: 1px; }
& .sub-menu{& .menu-item.MEC25, & .menu-item.MEC25 > div.menu-item-wrap{ & > a.MEC25, & > i{color: $COL11;
font-size: 19.2px;
@media #{$medium-up} {
font-size: 22.4px;
}text-align: left;
}
  }
 .sub-menu {& .menu-item.MEC25, & .menu-item.MEC25 > div.menu-item-wrap{ & > a.MEC25, & > i{color: $COL11;
text-align: left;
}
  }
}}

 }
/* Button 1:26 */
.MES26 {
background-color: $COL11;
&:hover { background-color: $COL28;}
color: $COL16;
 }
/* Background Image Paralex:27 */
.MES27 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://seagetransport.com.au/img/48/27');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 250%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
.MES27 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://seagetransport.com.au/img/48/27');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 250%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
/* Button 2:28 */
.MES28 {
background-color: $COL28;
&:hover { background-color: $COL25;}
color: $COL16;
&:hover { color: $COL11;}
 }
/* Job Opportunities:29 */
.MES29 {
border-width: 3px;
border-style: solid;
border-color: $COL27;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES29 {
border-width: 3px;
border-style: solid;
border-color: $COL27;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* :30 */
.MES30 {
background-color: $COL29;
&:hover, &.hover { background-color: $COL1;}
 }
.MES30 {
background-color: $COL29;
&:hover, &.hover { background-color: $COL1;}
 }
/* Dark Hover:31 */
.MES31 {
background-color: $COL31;
&:hover, &.hover { background-color: $COL29;}
color: $COL17;
 }
.MES31 {
background-color: $COL31;
&:hover, &.hover { background-color: $COL29;}
color: $COL17;
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: $COL17;
 }
&:hover { h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: $COL11; } }
 }
cite.MEC31{
color: $COL17;
}
/* :32 */
.MES32 {
& .slider-arrow {background-color: $COL1;
color: $COL26;
font-size: 70px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
} }
/* Home Pg Banner text:33 */
.MES33 {
color: $COL17;
 }
.MES33 {
color: $COL17;
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: $COL17;
 }
h1.MEC33 { @media #{$large-up} { font-size: 56px; }; }
h2.MEC33 { @media #{$large-up} { font-size: 49px; }; }
h3.MEC33 { @media #{$large-up} { font-size: 42px; }; }
h4.MEC33 { @media #{$large-up} { font-size: 35px; }; }
h5.MEC33 { @media #{$large-up} { font-size: 28px; }; }
h6.MEC33 { @media #{$large-up} { font-size: 25.2px; }; }
 }
cite.MEC33{
color: $COL17;
}
/* Dark Hover with Border:34 */
.MES34 {
background-color: $COL31;
&:hover, &.hover { background-color: $COL11;}
color: $COL17;
border-width: 5px;
border-style: solid;
border-color: $COL28;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES34 {
background-color: $COL31;
&:hover, &.hover { background-color: $COL11;}
color: $COL17;
border-width: 5px;
border-style: solid;
border-color: $COL28;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: $COL17;
 }
&:hover { h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: $COL11; } }
 }
cite.MEC34{
color: $COL17;
}
/* Copyright Panel:35 */
.MES35 {
background-color: $COL13;
color: $COL15;
font-size: 12.8px;
 }
.MES35 {
background-color: $COL13;
color: $COL15;
font-size: 12.8px;
h1.MEC35, h2.MEC35, h3.MEC35, h4.MEC35, h5.MEC35, h6.MEC35 { color: $COL15;
 }
 }
cite.MEC35{
color: $COL15;
font-size: 12.8px;
}
/* Top Banner Area:36 */
.MES36 {
background-color: $COL30;
& > .underlay, & > .inner-overlay { opacity: 0.7 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://seagetransport.com.au/img/1024/125');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:250px;
@media #{$medium-up} {
min-height: 350px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES36 {
background-color: $COL30;
& > .underlay, & > .inner-overlay { opacity: 0.7 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://seagetransport.com.au/img/1024/125');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:250px;
@media #{$medium-up} {
min-height: 350px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: $COL17;
 }
 }
/* Slider Panel:37 */
.MES37 {
background-color: $COL27;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 450px;};
 }
.MES37 {
background-color: $COL27;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 450px;};
 }
/* Button 3:38 */
.MES38 {
background-color: $COL27;
&:hover { background-color: $COL28;}
color: $COL16;
&:hover { color: $COL11;}
font-size: 16px;
@media #{$medium-up} {
font-size: 19.8px;
};
@media #{$large-up} {
font-size: 21.6px;
};
 }
/* Menu Popup BG:39 */
.MES39 {
background-color: $COL33;
color: $COL17;
 }
.MES39 {
background-color: $COL33;
color: $COL17;
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: $COL17;
 }
&:hover { h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: $COL11; } }
 }
cite.MEC39{
color: $COL17;
}
/* Upload File :40 */
.me-block > .field-wrap.MES40 { 
& label {  }
 }
/* Drop area :41 */
.MES41 {
min-height:40px;
@media #{$medium-up} {
min-height: 50px;};
@media #{$large-up} {
min-height: 100px;};
padding: 15px;

@media #{$large-up} {
padding: 30px;

}
border-width: 3px;
border-style: solid;
border-color: $COL2;
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
.MES41 {
min-height:40px;
@media #{$medium-up} {
min-height: 50px;};
@media #{$large-up} {
min-height: 100px;};
padding: 15px;

@media #{$large-up} {
padding: 30px;

}
border-width: 3px;
border-style: solid;
border-color: $COL2;
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
