$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #ffffff; //
$COLFLAT2: #ffffff; //
$COL3: #363435; //
$COLFLAT3: #363435; //
$COL4: #363435; //
$COLFLAT4: #363435; //
$COL5: #363435; //
$COLFLAT5: #363435; //
$COL6: #363435; //
$COLFLAT6: #363435; //
$COL7: #363435; //
$COLFLAT7: #363435; //
$COL8: #363435; //
$COLFLAT8: #363435; //
$COL9: #363435; //
$COLFLAT9: #363435; //
$COL10: #4a9fd3; //
$COLFLAT10: #4a9fd3; //
$COL11: #4a9fd3; //
$COLFLAT11: #4a9fd3; //
$COL12: #4a9fd3; //
$COLFLAT12: #4a9fd3; //
$COL13: #363435; //
$COLFLAT13: #363435; //
$COL14: #ffffff; //
$COLFLAT14: #ffffff; //
$COL15: #ffffff; //
$COLFLAT15: #ffffff; //
$COL16: #ffffff; //
$COLFLAT16: #ffffff; //
$COL17: #ffffff; //
$COLFLAT17: #ffffff; //
$COL18: #1d1d1d; //
$COLFLAT18: #1d1d1d; //
$COL19: #1d1d1d; //
$COLFLAT19: #1d1d1d; //
$COL20: #1d1d1d; //
$COLFLAT20: #1d1d1d; //
$COL21: #1d1d1d; //
$COLFLAT21: #1d1d1d; //
$COL22: #ffffff; //
$COLFLAT22: #ffffff; //
$COL23: #ffffff; //
$COLFLAT23: #ffffff; //
$COL24: #ffffff; //
$COLFLAT24: #ffffff; //
$COL25: #e8e8e8; //
$COLFLAT25: #e8e8e8; //
$COL26: #f2f2f2; //
$COLFLAT26: #f2f2f2; //
$COL27: #333333; //
$COLFLAT27: #333333; //
$COL28: #c1c1c1; //
$COLFLAT28: #c1c1c1; //
$COL29: rgba(0,0,0,0.239); //black
$COLFLAT29: #616161; //black
$COL30: #000000; //Black
$COLFLAT30: #000000; //Black
$COL31: rgba(0,0,0,0.502); //black
$COLFLAT31: #404040; //black
$COL32: rgba(0,0,0,0.6); //Header Scroll
$COLFLAT32: #333333; //Header Scroll
$COL33: rgba(29,29,29,0.871); //Black 90
$COLFLAT33: #2a2a2a; //Black 90
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
